<template>
  <div 
    id="bonus" 
    class="page"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="3"
      color="secondary darken-3"
    />
    
    <div
      v-if="items.length>0"
      id="bonus-scroll"
      class="content flex-grow-1 flex-shrink-0 px-4"
      ref="content"
    >
      <v-card 
        v-for="draw in upcomingDraws(items)"
        :key="draw.id"
        elevation="8" 
        class="card upcoming mb-4"
      >
        <v-card-text>
          <span class="date overline">
            <b>Próximo Sorteio: {{ draw.dateDraw }}</b>
          </span>
          <p class="prize title mt-4 mb-6">
            {{ prizeLabel(draw.prizes) }}
          </p>

          <p class="body-1 my-4">
            Total de bilhetes: {{ draw.ticketsTotal }}
          </p>

          <v-divider class="" />

          <p class="body-1 my-4">
            Meus bilhetes: {{ draw.tickets }}
          </p>
          <div 
            v-if="draw.tickets>0"
            class="my-tickets mx-n2 mb-2"
          >
            <v-chip
              class="ticket mb-2 mx-2"
            >
              {{ ticketLabel(draw.tickets, 0) }}
            </v-chip>
            <v-icon 
              v-if="draw.tickets>=2"
              class="dots"
            >
              {{ icons.mdiDotsHorizontal }}
            </v-icon>
            <v-chip
              v-if="draw.tickets>=2"
              class="ticket mb-2 mx-2"
            >
              {{ ticketLabel(draw.tickets, 1) }}
            </v-chip>
          </div>
          <div 
            v-else
            class="my-tickets no-tickets"
          >
            A partir de 160 horas no ar, você acumula bilhetes para este sorteio. 😉
          </div>
        </v-card-text>
      </v-card>

      <v-card 
        v-for="draw in pastDraws(items)"
        :key="draw.id"
        dark
        elevation="4" 
        class="card past black mb-4"
      >
        <v-img
          v-if="winner(draw.prizes).on===true"
          min-height="240px"
          class="won white--text align-end"
          src="@/assets/confetti.svg"
          position="top center"
          contain
        >
          <v-card-title class="headline">
            <span class="pb-4">
              Parabéns, {{ firstname }}! 
            </span>
            <span class="title text-h6">
              Você ganhou:
            </span>
            <span 
              v-for="(prize,i) in winner(draw.prizes).list"
              :key="i"
              class="title text-h6 item mt-2"
            >
              🎁 {{ prize.title }}
            </span>
          </v-card-title>
          <v-card-subtitle class="pt-4 pb-8">
            Entraremos em contato em breve para entregarmos seu prêmio.
          </v-card-subtitle>
        </v-img>
        <v-card-text>
          <span class="date overline font-weight-500">
            Sorteio {{ draw.dateDraw }}
          </span>
          <p class="prize title text-h6 mt-4 mb-6">
            {{ prizeLabel(draw.prizes) }}
          </p>

          <!-- <v-divider class="across" /> -->

          <p class="text-body-1 my-4">
            Total de bilhetes: {{ draw.ticketsTotal }}
          </p>
          
          <p class="text-body-1 my-4">
            Bilhetes sorteados: {{ validPrizes(draw.prizes).length }} 
          </p>
          <div 
            v-if="draw.tickets>0"
            class="drawn-tickets mr-n2 mb-6"
          >
            <v-chip
              v-for="(ticket,i) in validPrizes(draw.prizes)"
              :key="i"
              class="ticket mb-2 mr-2"
              :class="{ 'winner': ticket.winner !== null ? ticket.winner.indexOf(prefix)>=0 : false }"
            >
              {{ ticket.winner }}
            </v-chip>
          </div>

          <v-divider 
            class=""
            v-if="draw.tickets>0"
          />
          
          <p 
            class="text-body-1 my-4"
            v-if="draw.tickets>0"
          >
            Meus bilhetes: {{ draw.tickets }}
          </p>
          <div 
            v-if="draw.tickets>0"
            class="my-tickets mx-n2 mb-2"
          >
            <v-chip
              class="ticket mb-2 mx-2"
            >
              {{ ticketLabel(draw.tickets, 0) }}
            </v-chip>
            <v-icon 
              v-if="draw.tickets>=2"
              class="grey--text"
            >
              {{ icons.mdiDotsHorizontal }}
            </v-icon>
            <v-chip
              v-if="draw.tickets>=2"
              class="ticket mb-2 mx-2"
            >
              {{ ticketLabel(draw.tickets, 1) }}
            </v-chip>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div
      v-else
      class="empty-state d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-8" 
    >
      <img 
        class="empty-icon mx-auto"
        src="/img/empty-bonus.svg"
      >
      <h4 class="title text-h6 mt-12">
        Ainda Mais Prêmios
      </h4>
      <p class="text text-body-2 text--secondary my-6">
        Vamos sortear diversos prêmios para vocês! Quanto mais Horas no Ar acumuladas, mais chances você tem de ganhar.
      </p>
    </div>
  </div>
</template>

<style type="text/css">

  #bonus .card {
    border-radius: .25rem;
  }
  #bonus .card.upcoming {
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.16), 0px 4px 24px rgba(255, 255, 255, 0.08) !important;
  }

  .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.8);
  }
  .theme--dark.v-card > .v-card__text, .theme--dark.v-card .v-card__subtitle {
    color: rgba(255, 255, 255, 0.85);
  }
  
  #bonus .v-divider.across {
    margin-left: -16px;
    margin-right: -16px; 
    max-width: initial;
  }

  #bonus .card .date.overline {
    font-size: .6875rem !important;
  }

  #bonus .card .title {
    line-height: 1.4;
  }

  #bonus .card .v-icon {
    margin-bottom: 6px;
  }

  #bonus .card.upcoming .ticket {
    color: var(--v-warning-darken4);
    background-color: var(--v-warning-base);
  }

  #bonus .card.upcoming .my-tickets .dots {
    color: var(--v-warning-darken4);
  }

  #bonus .card.past {
    opacity: .8;
  }

  #bonus .card.past .drawn-tickets .ticket {
    opacity: .75;
    color: var(--v-accent-lighten2);
    background-color: transparent;
    border: 1px solid var(--v-accent-lighten2);
  }
  #bonus .card.past .drawn-tickets .winner.ticket {
    opacity: 1;
    color: var(--v-accent-darken3);
    background-color: var(--v-accent-lighten2);
    box-shadow: 0px 0px 8px rgba(51, 204, 184, 0.24), 0px 4px 32px rgba(51, 204, 184, 0.16);
  }

  #bonus .card .won {
    border-bottom: 1px solid rgba(255,255,255,.25);
    padding-top: 3rem;
  }

  #bonus .card .won .v-card__title {
    word-break: break-word;
    line-height: 1.25;
  }
  #bonus .card .won .v-card__title .item {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.24), 0px 4px 16px rgba(255, 255, 255, 0.16);
  }
  #bonus .card .won .v-card__subtitle {
    line-height: 1.4;
  }

  #bonus .card .won span {
    width: 100%;
  }

  #bonus .empty-state .text {
    opacity: .75;
  }

</style>

<script>
  // Icons
  import { mdiHelpCircleOutline, mdiClose, mdiDotsHorizontal } from '@mdi/js';

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';
  var moment = require('moment');


  export default {
    name: 'Bônus',

    props: {
      user: {
        type: Object,
        default: () => null
      },
      active: {
        type: Boolean,
        default: true
      },
      dev: {
        type: Boolean,
        default: false
      },
      installed: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
        mdiDotsHorizontal
      },
      offsetTop: 0,
    }),

    computed: {
      views: sync('app/views'),
      view: sync('app/views@bonus'),
      prefix: sync('bonus/prefix'),
      items: sync('bonus/items'),
      fullname: sync('user/profile@fullname'),
      help: get('help/sections'),
      toast: sync('app/toast'),

      firstname () {
        return this.fullname.split(' ')[0];
      },

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      },

      loading () {
        return this.view.loading;
      }
    },

    methods: {
      ...services,

      upcomingDraws (items) {
        // filter upcoming draws only
        let upcoming = _.filter(items, (item) => { 
          return item.dateClosed===null;
        });
        return upcoming;
      },

      pastDraws (items) {
        // filter past draws only
        let past = _.orderBy(_.filter(items, (item) => { 
          return item.dateClosed!==null;
        }), ['dateClosed'], ['desc']);
        return past;
      },

      validPrizes (prizes) {
        return _.filter(prizes, (prize) => {
          return prize.winner != null;
        });
      },

      prizeLabel (prizes) {
        // show humanized prizes text
        let label = '';
        let count = {};
        for (let p in prizes) {
          if (label.indexOf(prizes[p].title)==-1) {
            count[prizes[p].title] = 1;
            label += (label.length==0) ? '🎁 ' + prizes[p].title : ', 🎁 '+prizes[p].title;
          }else{
            count[prizes[p].title] += 1;
          }
        }
        for (let p in count) {
          // show count for repeated prizes
          if (count[p]>1) {
            label = label.replace(p, p+' ('+count[p]+')');
          }
        }
        return label.indexOf(null)>=0 ? '🎁 A definir' : label;
      },

      ticketLabel (tickets, n) {
        // set ticket labels (first) ... (last)
        const prefix = this.prefix + '-0001';
        const last = prefix.substring(0, prefix.length-tickets.toString().length) + tickets;
        return n===0 ? prefix : last;
      },

      winner (prizes) {
        // check if user won a given draw prize list
        let won = {
          text: '',
          on: false,
          list: _.filter(prizes, (prize) => {
            return prize.winner !== null ? prize.winner.indexOf(this.prefix)>=0 : false;
          })
        };
        if (won.list.length>0) {
          if (won.list.length===1) {
            won.text = won.list[0].title;
          }else{
            for (let i in won.list) {
              if (i==0) {
                won.text += won.list[i].title;
              }else if (i==won.list.length-1) {
                won.text += ' & ' + won.list[i].title;
              }else{
                won.text += ', ' + won.list[i].title;
              }
            }
          }
          won.on = true;
        }
        return won;
      },

      getData () {
        this.view.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        
        this.$api
          .get('/getbonus/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getBonus => ',response);
            if(response.data.retorno==200){
              this.prefix = response.data.prefixo;
              const list = JSON.parse(response.data.list);
              let items = [];
              for (let item in list) {
                items.push({
                  id: list[item].id,
                  dateDraw: list[item].dsc,
                  dateOpen: moment.utc(list[item].dt_abertura, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD'),
                  dateClosed: list[item].dt_fechamento !== null ? moment.utc(list[item].dt_fechamento, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD') : null,
                  tickets: list[item].tickets,
                  ticketsTotal: list[item].tickets_used,
                  prizes: []
                });
                const prizes = list[item].premios;
                for (let p in prizes) {
                  items[item].prizes.push({
                    id: prizes[p]['id-premio'],
                    title: prizes[p]['dsc-premio'],
                    winner: prizes[p]['winner']
                  });
                }
              }
              this.items = items;
            }else if(response.data.retorno==401){
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível atualizar a lista de sorteios. Verifique sua conexão à internet',
                5000,
                false
              );
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível atualizar a lista de sorteios. Verifique sua conexão à internet', true);
          })
          .finally(() => {
            this.view.loading = false;
          });
      },
    },

    mounted () {
      this.getData();
    }
  }
</script>
